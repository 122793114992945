import React, { useCallback, useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Box,
  Card,
  IconButton,
  Divider,
  Rating,
  Link,
} from "@mui/material";
import axios from "../../api";
import { useParams } from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PageLoader from "examples/ReuseFunctions/PageLoader";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import HistoryModal from "layouts/AdminTickets/HistoryModal";
import FormatAssigneeHistory from "examples/ReuseFunctions/FormatAssigneeHistory";
import FormatStatusHistoryData from "examples/ReuseFunctions/FormatStatusHistory";
import AddConversation from "examples/ReuseFunctions/AddConversation";
import { useSelector } from "react-redux";
import { useSnackbar } from "components/AlertMessages/SnackbarContext";
import Tracker from "examples/ReuseFunctions/Tracker";
// import AddComments from "examples/ReuseFunctions/AddComments";
const formatDate = (dateString) => {
  const date = new Date(dateString);
  const optionsDate = { year: "numeric", month: "long", day: "numeric" };
  const optionsTime = { hour: "numeric", minute: "2-digit", hour12: true };

  const formattedDate = new Intl.DateTimeFormat("en-US", optionsDate).format(
    date
  );
  const formattedTime = new Intl.DateTimeFormat("en-US", optionsTime).format(
    date
  );

  return `${formattedDate}, ${formattedTime}`;
};
const EmployeeViewTicket = () => {
  const { id } = useParams();
  const { fetchError } = useSnackbar();

  const user = useSelector((state) => state.auth.user);
  const [ticketData, setTicketData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [statusModalOpen, setStatusModalClose] = useState(false);
  const [assigneeModalOpen, setAssigneeModalClose] = useState(false);
  const [assigneeHistory, setAssigneeHistory] = useState(null);
  const [statusHistory, setStatusHistory] = useState(null);
  const navigate = useNavigate();

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const ticketResponse = await axios.get(`/tickets/${id}`);
      const { data } = ticketResponse;
      const ticketData = data?.list;
      if (ticketData?.assignee_history) {
        setAssigneeHistory(FormatAssigneeHistory(ticketData?.assignee_history));
      }
      if (ticketData?.status_history) {
        setStatusHistory(FormatStatusHistoryData(ticketData?.status_history));
      }
      setTicketData(ticketData);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      navigate("/employee-tickets-list");
      fetchError(err.response.data.error);
      console.error("Error fetching data", err);
    }
  }, [id, fetchError, navigate]);
  useEffect(() => {
    fetchData();
  }, [fetchData]);
  const handleMediaOpen = (fileName) => {
    const url = `${process.env.REACT_APP_CLOUD_FRONT_URL}/${fileName}`;
    window.open(url, "_blank", "noopener,noreferrer");
  };
  const handleStatusOpen = () => {
    setStatusModalClose(true);
  };

  const handleStatusClose = () => {
    setStatusModalClose(false);
  };
  const handleAssigneeOpen = () => {
    setAssigneeModalClose(true);
  };

  const handleAssigneeClose = () => {
    setAssigneeModalClose(false);
  };

  if (loading && ticketData) {
    return <PageLoader />;
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card
        className="view-ticket"
        style={{
          padding: "30px",
          width: "100%",
          borderRadius: "8px",
        }}
      >
        <Grid
          item
          xs={12}
          display={"flex"}
          justifyContent={"center"}
          className="view-ticket-title"
        >
          <IconButton className="back-btn">
            <ArrowBackIcon onClick={() => navigate("/admin/tickets-list")} />
          </IconButton>
          <Typography variant="h5" gutterBottom>
            Ticket Details
          </Typography>
        </Grid>

        <Tracker trackingData={ticketData} />
        <Grid container className="view-ticket-details">
          <Grid item xs={4} sm={4} md={2} sx={{ my: 1 }}>
            <Typography variant="h6">
              <Box display="flex" justifyContent="left">
                <span>Category</span>
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={8} sm={8} md={4} sx={{ my: 1 }}>
            <Typography variant="h6" sx={{ fontWeight: "normal" }}>
              <Box display="flex" justifyContent="flex-start">
                <span>: {ticketData?.category_name}</span>
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={4} sm={4} md={2} sx={{ my: 1 }}>
            <Typography variant="h6">
              <Box display="flex" justifyContent="left">
                <span>Customer Name</span>
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={8} sm={8} md={4} sx={{ my: 1 }}>
            <Typography variant="h6" sx={{ fontWeight: "normal" }}>
              <Box display="flex" justifyContent="flex-start">
                <span>: {ticketData?.customer_name}</span>
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={4} sm={4} md={2} sx={{ my: 1 }}>
            <Typography variant="h6">
              <Box display="flex" justifyContent="left">
                <span>Customer Mobile</span>
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={8} sm={8} md={4} sx={{ my: 1 }}>
            <Typography variant="h6" sx={{ fontWeight: "normal" }}>
              <Box display="flex" justifyContent="flex-start">
                <span>: {ticketData?.customer_phone}</span>
              </Box>
            </Typography>
          </Grid>

          <Grid item xs={4} sm={4} md={2} sx={{ my: 1 }}>
            <Typography variant="h6">
              <Box display="flex" justifyContent="left">
                <span>Customer Email</span>
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={8} sm={8} md={4} sx={{ my: 1 }}>
            <Typography variant="h6" sx={{ fontWeight: "normal" }}>
              <Box display="flex" justifyContent="flex-start">
                <span>: {ticketData?.customer_email}</span>
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={4} sm={4} md={2} sx={{ my: 1 }}>
            <Typography variant="h6">
              <Box display="flex" justifyContent="left">
                <span>Created At</span>
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={8} sm={8} md={4} sx={{ my: 1 }}>
            <Typography variant="h6" sx={{ fontWeight: "normal" }}>
              <Box display="flex" justifyContent="flex-start">
                <span>
                  :{" "}
                  {ticketData?.created_at
                    ? formatDate(ticketData?.created_at)
                    : ""}
                </span>
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={4} sm={4} md={2} sx={{ my: 1 }}>
            <Typography variant="h6">
              <Box display="flex" justifyContent="left">
                <span>Assignee</span>
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={8} sm={8} md={4} sx={{ my: 1 }}>
            <Typography variant="h6" sx={{ fontWeight: "normal" }}>
              <Box display="flex" justifyContent="flex-start">
                <span>: {ticketData?.employee_name}</span>
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={4} sm={4} md={2} sx={{ my: 1 }}>
            <Typography variant="h6">
              <Box display="flex" justifyContent="left">
                <span>Status</span>
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={8} sm={8} md={4} sx={{ my: 1 }}>
            <Typography variant="h6" sx={{ fontWeight: "normal" }}>
              <Box display="flex" justifyContent="flex-start">
                <span>: {ticketData?.status_name}</span>
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={4} sm={4} md={2} sx={{ my: 1 }}>
            <Typography variant="h6">
              <Box display="flex" justifyContent="left">
                <span>Status History</span>
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={8} sm={8} md={4} sx={{ my: 1 }}>
            <Typography variant="h6" sx={{ fontWeight: "normal" }}>
              <Box display="flex" justifyContent="flex-start">
                <Link
                  style={{ cursor: "pointer" }}
                  onClick={() => handleStatusOpen()}
                >
                  Click here
                </Link>
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={4} sm={4} md={2} sx={{ my: 1 }}>
            <Typography variant="h6">
              <Box display="flex" justifyContent="left">
                <span>Assignee History</span>
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={8} sm={8} md={4} sx={{ my: 1 }}>
            <Typography variant="h6" sx={{ fontWeight: "normal" }}>
              <Box display="flex" justifyContent="flex-start">
                <Link
                  style={{ cursor: "pointer" }}
                  onClick={() => handleAssigneeOpen()}
                >
                  Click here
                </Link>
              </Box>
            </Typography>
          </Grid>
          <Grid item md={6} sx={{ my: 1 }}></Grid>
          <Grid item xs={4} sm={4} md={2} sx={{ my: 1 }}>
            <Typography variant="h6">
              <Box display="flex" justifyContent="left">
                <span>Address</span>
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={8} sm={8} md={10} sx={{ my: 1 }}>
            <Typography variant="h6" sx={{ fontWeight: "normal" }}>
              <Box display="flex" justifyContent="flex-start">
                <span>
                  :{" "}
                  {`${ticketData?.address}, ${ticketData?.city_name}, ${ticketData?.state_name}, ${ticketData?.address_type}`}
                </span>
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={4} sm={4} md={2} sx={{ my: 1 }}>
            <Typography variant="h6">
              <Box display="flex" justifyContent="left">
                <span>Description</span>
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={8} sm={8} md={10} sx={{ my: 1 }}>
            <Typography variant="h6" sx={{ fontWeight: "normal" }}>
              <Box display="flex" justifyContent="flex-start">
                <span>: {ticketData?.description}</span>
              </Box>
            </Typography>
          </Grid>
          {ticketData?.rating && (
            <>
              <Grid item xs={4} sm={4} md={2} sx={{ my: 1 }}>
                <Typography variant="h6">
                  <Box display="flex" justifyContent="left">
                    <span>Rating</span>
                  </Box>
                </Typography>
              </Grid>
              <Grid item xs={8} sm={8} md={10} sx={{ my: 1 }}>
                <Typography variant="h6" sx={{ fontWeight: "normal" }}>
                  <Box display="flex" justifyContent="flex-start">
                    <span>
                      :{" "}
                      <Rating
                        name="simple-controlled"
                        value={ticketData?.rating}
                        readOnly
                      />
                    </span>
                  </Box>
                </Typography>
              </Grid>
            </>
          )}
          {ticketData?.feedback && (
            <>
              <Grid item xs={4} sm={4} md={2} sx={{ my: 1 }}>
                <Typography variant="h6">
                  <Box display="flex" justifyContent="left">
                    <span>Feedback</span>
                  </Box>
                </Typography>
              </Grid>
              <Grid item xs={8} sm={8} md={10} sx={{ my: 1 }}>
                <Typography variant="h6" sx={{ fontWeight: "normal" }}>
                  <Box display="flex" justifyContent="flex-start">
                    <span>: {ticketData?.feedback}</span>
                  </Box>
                </Typography>
              </Grid>
            </>
          )}
          {ticketData?.customer_reject_reason && (
            <>
              <Grid item xs={4} sm={4} md={2} sx={{ my: 1 }}>
                <Typography variant="h6">
                  <Box display="flex" justifyContent="left">
                    <span>Reject Reason</span>
                  </Box>
                </Typography>
              </Grid>
              <Grid item xs={8} sm={8} md={10} sx={{ my: 1 }}>
                <Typography variant="h6" sx={{ fontWeight: "normal" }}>
                  <Box display="flex" justifyContent="flex-start">
                    <span>: {ticketData?.customer_reject_reason}</span>
                  </Box>
                </Typography>
              </Grid>
            </>
          )}
          {ticketData?.pending_reason && (
            <>
              <Grid item xs={4} sm={4} md={2} sx={{ my: 1 }}>
                <Typography variant="h6">
                  <Box display="flex" justifyContent="left">
                    <span>Pending Reason</span>
                  </Box>
                </Typography>
              </Grid>
              <Grid item xs={8} sm={8} md={10} sx={{ my: 1 }}>
                <Typography variant="h6" sx={{ fontWeight: "normal" }}>
                  <Box display="flex" justifyContent="flex-start">
                    <span>: {ticketData?.pending_reason}</span>
                  </Box>
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
        <Divider sx={{ height: 2, backgroundColor: "blue" }} />
        <Grid col item sm={6} md={6}>
          <Typography variant="h5" gutterBottom>
            Customer
          </Typography>
          <div className="comments-conv">
            {ticketData && (
              <AddConversation
                user={user}
                data={ticketData}
                fetchData={fetchData}
              />
            )}
          </div>
        </Grid>

        {/* <Grid container>
          <Grid col item sm={6} md={6}>
            <Typography variant="h5" gutterBottom>
              Service Provider
            </Typography>
            <div className="comments-conv">
              {ticketData && (
                <AddComments
                  user={user}
                  ticket={ticketData}
                  fetchData={fetchData}
                />
              )}
            </div>
          </Grid>
          <Grid col item sm={6} md={6}>
            <Typography variant="h5" gutterBottom>
              Customer
            </Typography>
            <div className="comments-conv">
              {ticketData && (
                <AddConversation
                  user={user}
                  data={ticketData}
                  fetchData={fetchData}
                />
              )}
            </div>
          </Grid>
        </Grid> */}
        <Divider sx={{ my: 2, height: 2, backgroundColor: "blue" }} />
        <div className="view-ticket-details">
          <Typography variant="h5" gutterBottom>
            Media
          </Typography>
          {ticketData?.multimedia.length > 0 ? (
            <div className="upload-media ticket-media">
              {ticketData?.multimedia?.map((media, index) => (
                <div className="media-container view-media" key={index}>
                  {media.file_type === "Photo" ? (
                    <img
                      src={`${process.env.REACT_APP_CLOUD_FRONT_URL}/${media.file_name}`}
                      alt={`media-${index}`}
                      onClick={() => handleMediaOpen(media?.file_name)}
                    />
                  ) : (
                    <video
                      controls // Add controls so users can play/pause the video
                      width="100%" // Adjust width as needed
                      height="140px" // Adjust height as needed
                      onClick={() => handleMediaOpen(media?.file_name)}
                    >
                      <source
                        src={`${process.env.REACT_APP_CLOUD_FRONT_URL}/${media.file_name}`}
                        type="video/mp4" // Specify the MIME type of your video file
                      />
                    </video>
                  )}
                </div>
              ))}
            </div>
          ) : (
            <Typography variant="h6" sx={{ fontWeight: "normal" }}>
              <span>No Media</span>
            </Typography>
          )}
        </div>
        {assigneeModalOpen && (
          <HistoryModal
            title={"Assignee"}
            data={assigneeHistory}
            isOpen={assigneeModalOpen}
            onClose={handleAssigneeClose}
          />
        )}
        {statusModalOpen && (
          <HistoryModal
            title={"Status"}
            data={statusHistory}
            isOpen={statusModalOpen}
            onClose={handleStatusClose}
          />
        )}
      </Card>
    </DashboardLayout>
  );
};

export default EmployeeViewTicket;
