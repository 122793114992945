import React, { useState, useEffect } from "react";
import axios, { setAuthToken } from "../src/api";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";
import theme from "assets/theme";
import { useSoftUIController, setMiniSidenav } from "context";
import brand from "assets/images/innovative-Logo.png";
import ForgotPassword from "layouts/authentication/passwords/ForgotPassword";
import PasswordReset from "layouts/authentication/passwords/PasswordReset";
import { useSelector, useDispatch } from "react-redux";
import { userLogin } from "store/reducers/auth";
import { privateRoutes } from "routes";
// import SignIn from "layouts/authentication/sign-in";
// import SignUp from "layouts/authentication/sign-up";
import AdminSignIn from "layouts/authentication/admin-sign-in/AdminSignIn";
import NotFound from "layouts/NotFound";
import PageLoader from "examples/ReuseFunctions/PageLoader";
import Home from "layouts/Home";
import About from "layouts/Home/About";
import Contact from "layouts/Home/Contact";
import PrivacyPolicy from "layouts/Home/PrivacyPolicy";
import TermsConditions from "layouts/Home/TermsConditions";
import ReUsePage from "layouts/Home/ReUsePage";
import AllPages from "examples/ReuseFunctions/AllPages";
// import SuperAdminSignIn from "layouts/authentication/superAdmin-signin";
export default function App() {
  const { pagesData, logo } = AllPages();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const getAuthUser = () => {
      const token = localStorage.getItem("token");
      if (!token) {
        dispatch(userLogin({ user: undefined }));
        setLoading(false);
        return;
      }

      setAuthToken(token);
      axios
        .get("/auth")
        .then((response) => {
          dispatch(userLogin({ user: response.data }));
          setLoading(false);
        })
        .catch((error) => {
          dispatch(userLogin({ user: undefined }));
          setLoading(false);
        });
    };

    getAuthUser();
  }, [dispatch]);

  const [controller, dispatched] = useSoftUIController();
  const { miniSidenav, direction, layout, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatched, false);
      setOnMouseEnter(true);
    }
  };

  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatched, true);
      setOnMouseEnter(false);
    }
  };

  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const shouldRenderSidenav = (pathname, userRole) => {
    const routeAccess = {
      // "/super-admins": ["superAdmin"],
      // "/clients-list": ["superAdmin"],
      // "/super-admin/profile": ["superAdmin"],
      // "/add-superAdmin": ["superAdmin"],
      // "/edit-superAdmin": ["superAdmin"],
      // "/add-client": ["superAdmin"],
      // "/edit-client": ["superAdmin"],
      "/supervisors-list": ["admin"],
      // "/admin/lifts-list": ["admin"],
      // "/admin/lifts-view": ["admin"],
      "/add-supervisor": ["admin"],
      "/edit-supervisor": ["admin"],
      "/pages-list": ["admin"],
      "/add-page": ["admin"],
      "/edit-page": ["admin"],
      "/assets-list": ["admin"],
      "/add-subscriptiontype": ["admin"],
      "/employee/ticket-view": ["employee"],
      "/employee-tickets-list": ["employee"],
      "/dashboard": ["admin", "supervisor", "employee"],
      "/admin/profile": ["admin", "supervisor", "employee"],
      "/admin/edit-ticket": ["admin", "supervisor", "employee"],
      "/admin/tickets-list": ["admin", "supervisor"],
      "/general-preference": ["admin", "supervisor"],
      "/employees-list": ["admin", "supervisor"],
      "/add-employee": ["admin", "supervisor"],
      "/edit-employee": ["admin", "supervisor"],
      "/customers-list": ["admin", "supervisor"],
      "/subscriptions-list": ["admin", "supervisor"],
      "/add-subscription": ["admin", "supervisor"],
      "/edit-subscription": ["admin", "supervisor"],
      "/add-customer": ["admin", "supervisor"],
      "/edit-customer": ["admin", "supervisor"],
      "/admin/add-ticket": ["admin", "supervisor"],
      "/locations": ["admin", "supervisor"],
      "/admin/ticket-view": ["admin", "supervisor"],
      "/address-view": ["admin", "supervisor"],
      "/templates-list": ["admin", "supervisor"],
      "/types": ["admin", "supervisor"],
      "/add-ticket": ["admin", "customer"],
      // "/customer/lifts-view": ["customer"],
      "/customer/tickets-list": ["customer"],
      "/customer/edit-ticket": ["customer"],
      // "/customer/lifts-list": ["customer"],
      "/customer/ticket-view": ["customer"],
      "/customer/profile": ["customer"],
      "/customer/assets-list": ["customer"],
      "/customer/assets-view": ["customer"],
    };

    for (const routePath in routeAccess) {
      if (
        pathname.includes(routePath) &&
        routeAccess[routePath].includes(userRole)
      ) {
        return true;
      }
    }
    return false;
  };

  const renderSidenav = shouldRenderSidenav(pathname, user?.Role[0]);
  const renderRoutes = (routes) =>
    routes.map((route, index) => {
      if (route.collapse) {
        return renderRoutes(route.collapse);
      }
      if (route.route) {
        if (user) {
          if (shouldRenderSidenav(route.route, user?.Role[0])) {
            return (
              <Route
                exact
                path={route.route}
                element={route.component}
                key={route.key || index}
              />
            );
          } else {
            return (
              <Route
                exact
                path={route.route}
                element={
                  <Navigate
                    to={user.roleId === 5 ? "/profile" : "/dashboard"}
                  />
                }
                key={route.key || index}
              />
            );
          }
        }
        return null;
      }
      return null;
    });

  if (loading) {
    return <PageLoader />;
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          {renderSidenav && (
            <Sidenav
              color={sidenavColor}
              brand={brand}
              brandName="Innovative Lifts"
              routes={privateRoutes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
              logo={logo}
            />
          )}
          <Configurator />
        </>
      )}
      {layout === "vr" && <Configurator />}
      <Routes>
        {renderRoutes(privateRoutes)}
        {user ? (
          <Route
            path="*"
            element={
              <Navigate
                to={user.roleId === 5 ? "/customer/profile" : "/dashboard"}
              />
            }
          />
        ) : (
          <Route path="*" element={<Navigate to="/home" />} />
        )}
        <Route path="/" element={<Navigate to="/home" />} />
        {/* <Route path="/" element={<Navigate to="/sign-in" />} /> */}
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:token" element={<PasswordReset />} />
        <Route path="/employee/sign-in" element={<AdminSignIn />} />
        {/* <Route path="/superadmin/sign-in" element={<SuperAdminSignIn />} /> */}
        <Route path="/home" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-conditions" element={<TermsConditions />} />
        {/* <Route path="/sign-in" element={<SignIn />} /> */}
        {pagesData &&
          pagesData.map((data) => {
            return (
              <Route
                path={`/${data?.page_route}`}
                element={<ReUsePage pageData={data} />}
              />
            );
          })}
        <Route
          path="/unauthorized"
          element={<Navigate to="/employee/sign-in" />}
        />
        {/* <Route path="/sign-up" element={<SignUp />} /> */}
        <Route path="*" element={<NotFound user={user} />} />
      </Routes>
    </ThemeProvider>
  );
}
