import React, { useState, useEffect, useCallback } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  TextField,
  Button,
  Grid,
  Card,
  Box,
  Autocomplete,
  IconButton,
} from "@mui/material";
import axios from "../../api";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { useNavigate } from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useSelector } from "react-redux";
import { useSnackbar } from "components/AlertMessages/SnackbarContext";
import AWS from "aws-sdk";
import { useDropzone } from "react-dropzone";
import DeleteIcon from "@mui/icons-material/Delete";
import DateFormat from "examples/ReuseFunctions/DateFormat";
import StatusHistory from "examples/ReuseFunctions/StatusHistory";

const validationSchema = Yup.object({
  asset_type_id: Yup.string().required("Asset Type is required"),
  asset_id: Yup.string().required("Asset is required"),
  description: Yup.string().required("Description is required"),
  address_id: Yup.string().required("Address is required"),
  // file_name: Yup.mixed().required("Upload is required"),
});
const s3 = new AWS.S3({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION,
});
const initialValues = {
  description: "",
  address_id: "",
  file_name: "",
  file_type: "",
  asset_type_id: "",
  asset_id: "",
};

const AddTicket = () => {
  const currentTime = DateFormat();
  const [selectedAssetId, setSelectedAssetId] = useState(null);

  const [selectedAddress, setSelectedAddress] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const { fetchError, fetchSuccess } = useSnackbar();
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);

  const [assetsData, setAssetsData] = React.useState(null);

  const fetchAssetsType = useCallback(async () => {
    try {
      const response = await axios.get("/asset-types", {
        params: {
          is_active: true,
        },
      });
      const assetData =
        response.data.list && Array.isArray(response.data.list)
          ? response.data.list
          : [];

      setAssetsData(assetData);
    } catch (error) {
      console.error(error.response?.data?.error);
    }
  }, []);

  useEffect(() => {
    fetchAssetsType();
  }, [fetchAssetsType]);

  const [assets, setAssets] = useState([]);
  const fetchAssets = React.useCallback(async () => {
    try {
      const response = await axios.get(
        `/assets/${selectedAssetId}/${user.userId}`
      );
      setAssets(response?.data?.list);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  }, [selectedAssetId, user.userId]);
  React.useEffect(() => {
    fetchAssets();
  }, [fetchAssets]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const dropzone = useDropzone({
    accept: {
      "image/png": [".png", ".jpeg", ".jpg"],
      "video/mp4": [".mp4"],
    },
    onDrop: (acceptedFiles) => {
      // Append new files to the existing uploadedFiles array
      setUploadedFiles((prevFiles) => [
        ...prevFiles,
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
      ]);
    },
  });

  const handleDelete = (index) => {
    setUploadedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card
        style={{
          padding: "30px",
          width: "100%",
          borderRadius: "8px",
        }}
      >
        <SoftTypography variant="h5" style={{ marginBottom: "18px" }}>
          Add New Ticket
        </SoftTypography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            setSubmitting(true);
            const statusdata = StatusHistory(null, user?.name, "Open");
            const ticketData = {
              description: values.description,
              address_id: values.address_id,
              customer_id: user?.userId,
              status_id: 1,
              status_history: statusdata,
              asset_id: values.asset_id,
              asset_type_id: values.asset_type_id,
            };
            var statusTracker = [];
            statusTracker[0] = {
              updatedDate: currentTime,
              status: "open",
              status_id: 0,
              changedBy: user.name,
            };
            ticketData.status_tracker = JSON.stringify(statusTracker);

            let mediaDataArray = [];

            try {
              if (uploadedFiles && uploadedFiles.length > 0) {
                mediaDataArray = await Promise.all(
                  uploadedFiles.map(async (file) => {
                    const fileName = `${Date.now()}-${file.name}`;

                    const params = {
                      Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
                      Key: fileName,
                      Body: file,
                      ContentType: file.type,
                    };

                    let type = file.type.split("/")[0];
                    if (type === "image") {
                      type = "Photo";
                    }

                    const mediaData = {
                      file_name: fileName,
                      file_type: type,
                      file_path: fileName,
                    };

                    return new Promise((resolve, reject) => {
                      s3.upload(params, (err, data) => {
                        if (err) {
                          console.error("Error uploading file:", err);
                          reject(err);
                        } else {
                          resolve(mediaData);
                        }
                      });
                    });
                  })
                );

                try {
                  const response = await axios.post("/tickets", {
                    ticketData,
                    mediaData: mediaDataArray,
                  });
                  navigate("/customer/tickets-list");
                  fetchSuccess(response.data.message);
                } catch (error) {
                  fetchError(error.response.data.error);
                } finally {
                  setSubmitting(false);
                }
              } else {
                try {
                  const response = await axios.post("/tickets", {
                    ticketData,
                  });
                  navigate("/customer/tickets-list");
                  fetchSuccess(response.data.message);
                } catch (error) {
                  fetchError(error.response.data.error);
                } finally {
                  setSubmitting(false);
                }
              }
            } catch (error) {
              fetchError(error.response.data.error);
              setSubmitting(false);
            }
          }}
        >
          {({ errors, touched, setFieldValue, values }) => (
            <Form
              className="new-ticket-form"
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <SoftBox>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Asset Type
                    </SoftTypography>
                    <Autocomplete
                      options={assetsData || []}
                      getOptionLabel={(option) => option.asset_type_name}
                      onChange={(event, value) => {
                        setFieldValue(
                          "asset_type_id",
                          value?.asset_type_id || ""
                        );
                        setSelectedAssetId(value?.asset_type_id);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={errors.asset_type_id && touched.asset_type_id}
                          helperText={
                            touched.asset_type_id && errors.asset_type_id
                          }
                        />
                      )}
                    />
                  </SoftBox>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SoftBox>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Asset
                    </SoftTypography>
                    <Autocomplete
                      options={assets || []}
                      getOptionLabel={(option) => option.asset_name}
                      onChange={(event, value) => {
                        setSelectedAddress(value);
                        setFieldValue("asset_id", value?.asset_id || "");
                        setFieldValue(
                          "address_id",
                          value ? value.address_id : ""
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={errors.asset_id && touched.asset_id}
                          helperText={touched.asset_id && errors.asset_id}
                        />
                      )}
                    />
                  </SoftBox>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SoftBox>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Description
                    </SoftTypography>
                    <Field
                      name="description"
                      as={TextField}
                      variant="outlined"
                      fullWidth
                      error={!!errors.description && touched.description}
                      helperText={touched.description && errors.description}
                    />
                  </SoftBox>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SoftBox>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Address
                    </SoftTypography>
                    <TextField
                      name="address_id"
                      fullWidth
                      value={
                        selectedAddress
                          ? `${selectedAddress?.address_type}-${selectedAddress?.city_name}-${selectedAddress?.region_name}`
                          : ""
                      }
                      variant="outlined"
                      disabled
                    />
                  </SoftBox>
                </Grid>
                <Grid item xs={12} md={12}>
                  {uploadedFiles.length > 0 && (
                    <div className="upload-media">
                      {uploadedFiles.map((file, index) => (
                        <div
                          key={index}
                          className="media-container"
                          style={{ position: "relative" }}
                        >
                          {file.preview &&
                            (file.type.startsWith("image") ? (
                              <img
                                src={file.preview}
                                alt={`media-${index}`}
                                className="upload-img"
                              />
                            ) : (
                              <video
                                className="upload-video"
                                controls
                                width="100%"
                                height="140px"
                              >
                                <source src={file.preview} type="video/mp4" />
                              </video>
                            ))}
                          <IconButton
                            onClick={() => handleDelete(index)}
                            style={{ position: "absolute", top: 5, right: 5 }}
                            color="secondary"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </div>
                      ))}
                    </div>
                  )}
                  <Box
                    sx={{ cursor: "pointer", width: "fit-content" }}
                    {...dropzone.getRootProps()}
                  >
                    <Box>
                      <label htmlFor="image-upload-input">
                        <input
                          name="file_name"
                          {...dropzone.getInputProps({
                            name: "file_name",
                          })}
                        />
                        <Button
                          component="span"
                          variant="contained"
                          color="primary"
                          className="upload-btn"
                        >
                          Upload File
                        </Button>
                      </label>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Button
                    className="success-btn"
                    sx={{
                      position: "relative",
                      minWidth: 100,
                    }}
                    variant="contained"
                    disabled={submitting}
                    type="submit"
                    color="success"
                  >
                    Save
                  </Button>

                  <Button
                    className="cancel-btn"
                    sx={{
                      position: "relative",
                      minWidth: 100,
                      ml: 2.5,
                    }}
                    variant="contained"
                    color="warning"
                    onClick={() => navigate(`/customer/tickets-list`)}
                    type="cancel"
                  >
                    Cancel
                  </Button>
                </Box>
              </Grid>
            </Form>
          )}
        </Formik>
      </Card>
    </DashboardLayout>
  );
};

export default AddTicket;
