import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Box,
  Divider,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Card,
  IconButton,
} from "@mui/material";
import moment from "moment";
import PageLoader from "examples/ReuseFunctions/PageLoader";
import axios from "../../api";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "components/AlertMessages/SnackbarContext";

const CustomerViewAsset = () => {
  const { id } = useParams();
  const { fetchError } = useSnackbar();
  const navigate = useNavigate();
  const [assetData, setAssetData] = useState(null);
  const [subscriptions, setSubscriptions] = useState(null);
  const [loading, setLoading] = useState(true);

  const handleMediaOpen = (fileName) => {
    const url = `${process.env.REACT_APP_CLOUD_FRONT_URL}/${fileName}`;
    window.open(url, "_blank", "noopener,noreferrer");
  };
  useEffect(() => {
    const fetchAddressData = async () => {
      setLoading(true);
      try {
        const [assetResponse, subscriptionResponse] = await Promise.all([
          axios.get(`/assets/${id}`),
          axios.get(`/subscription/assets/${id}`),
        ]);

        setAssetData(assetResponse?.data);
        setSubscriptions(subscriptionResponse?.data?.list);
      } catch (err) {
        navigate("/customer/assets-list");
        fetchError(err.response.data.error);
        console.error("Error fetching data", err);
      } finally {
        setLoading(false);
      }
    };

    fetchAddressData();
  }, [id, fetchError, navigate]);
  if (loading) {
    return <PageLoader />;
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card
        className="view-ticket"
        style={{
          padding: "30px",
          width: "100%",
          borderRadius: "8px",
        }}
      >
        <Grid
          item
          xs={12}
          display={"flex"}
          justifyContent={"center"}
          className="view-ticket-title"
        >
          <IconButton className="back-btn">
            <ArrowBackIcon onClick={() => navigate("/customer/assets-list")} />
          </IconButton>
          <Typography variant="h5" gutterBottom>
            Asset Details
          </Typography>
        </Grid>
        <Grid container className="view-ticket-details">
          <Grid item xs={4} sm={4} md={2} sx={{ my: 1 }}>
            <Typography variant="h6">
              <Box display="flex" justifyContent="left">
                <span>Asset Type Id</span>
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={8} sm={8} md={4} sx={{ my: 1 }}>
            <Typography variant="h6" sx={{ fontWeight: "normal" }}>
              <Box display="flex" justifyContent="flex-start">
                <span>: {assetData?.asset_service_id}</span>
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={4} sm={4} md={2} sx={{ my: 1 }}>
            <Typography variant="h6">
              <Box display="flex" justifyContent="left">
                <span>Asset Name</span>
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={8} sm={8} md={4} sx={{ my: 1 }}>
            <Typography variant="h6" sx={{ fontWeight: "normal" }}>
              <Box display="flex" justifyContent="flex-start">
                <span>:{assetData?.asset_name}</span>
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={4} sm={4} md={2} sx={{ my: 1 }}>
            <Typography variant="h6">
              <Box display="flex" justifyContent="left">
                <span>Customer</span>
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={8} sm={8} md={4} sx={{ my: 1 }}>
            <Typography variant="h6" sx={{ fontWeight: "normal" }}>
              <Box display="flex" justifyContent="flex-start">
                <span>: {assetData?.customer_name}</span>
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={4} sm={4} md={2} sx={{ my: 1 }}>
            <Typography variant="h6">
              <Box display="flex" justifyContent="left">
                <span>Customer Phone</span>
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={8} sm={8} md={4} sx={{ my: 1 }}>
            <Typography variant="h6" sx={{ fontWeight: "normal" }}>
              <Box display="flex" justifyContent="flex-start">
                <span>: {assetData?.customer_phone}</span>
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={4} sm={4} md={2} sx={{ my: 1 }}>
            <Typography variant="h6">
              <Box display="flex" justifyContent="left">
                <span>Address</span>
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={8} sm={8} md={10} sx={{ my: 1 }}>
            <Typography variant="h6" sx={{ fontWeight: "normal" }}>
              <Box display="flex" justifyContent="flex-start">
                <span>
                  :{" "}
                  {`${assetData?.address}, ${assetData?.city_name}, ${assetData?.state_name}, ${assetData?.address_type}`}
                </span>
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={4} sm={4} md={2} sx={{ my: 1 }}>
            <Typography variant="h6">
              <Box display="flex" justifyContent="left">
                <span>Subscription Creation Date</span>
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={8} sm={8} md={4} sx={{ my: 1 }}>
            <Typography variant="h6" sx={{ fontWeight: "normal" }}>
              <Box display="flex" justifyContent="flex-start">
                <span>
                  :{" "}
                  {assetData?.subscription_creation_date
                    ? moment(assetData?.subscription_creation_date).format(
                        "MMM D, YYYY"
                      )
                    : ""}
                </span>
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={4} sm={4} md={2} sx={{ my: 1 }}>
            <Typography variant="h6">
              <Box display="flex" justifyContent="left">
                <span>Subscription Type</span>
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={8} sm={8} md={4} sx={{ my: 1 }}>
            <Typography variant="h6" sx={{ fontWeight: "normal" }}>
              <Box display="flex" justifyContent="flex-start">
                <span>: {assetData?.subscription_type}</span>
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={4} sm={4} md={2} sx={{ my: 1 }}>
            <Typography variant="h6">
              <Box display="flex" justifyContent="left">
                <span>Subscription Start Date</span>
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={8} sm={8} md={4} sx={{ my: 1 }}>
            <Typography variant="h6" sx={{ fontWeight: "normal" }}>
              <Box display="flex" justifyContent="flex-start">
                <span>
                  :{" "}
                  {assetData?.subscription_start_date
                    ? moment(assetData?.subscription_start_date).format(
                        "MMM D, YYYY"
                      )
                    : ""}
                </span>
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={4} sm={4} md={2} sx={{ my: 1 }}>
            <Typography variant="h6">
              <Box display="flex" justifyContent="left">
                <span>Subscription End Date</span>
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={8} sm={8} md={4} sx={{ my: 1 }}>
            <Typography variant="h6" sx={{ fontWeight: "normal" }}>
              <Box display="flex" justifyContent="flex-start">
                <span>
                  :{" "}
                  {assetData?.subscription_end_date
                    ? moment(assetData?.subscription_end_date).format(
                        "MMM D, YYYY"
                      )
                    : ""}
                </span>
              </Box>
            </Typography>
          </Grid>
        </Grid>
        {subscriptions && (
          <>
            <Divider sx={{ height: 2, backgroundColor: "blue" }} />
            <Typography variant="h5">Subscription History</Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead sx={{ display: "table-row-group" }}>
                  <TableRow>
                    <TableCell>Subscription Type</TableCell>
                    <TableCell>Created Date</TableCell>
                    <TableCell>End Date</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {subscriptions?.map((data, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Typography variant="h6" sx={{ fontWeight: "normal" }}>
                          <Box>
                            <span>{data?.subscription_type}</span>
                          </Box>
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6" sx={{ fontWeight: "normal" }}>
                          <Box>
                            <span>
                              {moment(data?.created_at).format("MMM D, YYYY")}
                            </span>
                          </Box>
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6" sx={{ fontWeight: "normal" }}>
                          <Box>
                            <span>
                              {moment(data?.end_date).format("MMM D, YYYY")}
                            </span>
                          </Box>
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6" sx={{ fontWeight: "normal" }}>
                          <Box>
                            <span>
                              {data?.is_active ? "Active" : "In-Active"}
                            </span>
                          </Box>
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              {!subscriptions?.length > 0 && (
                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                  <Typography variant="body2">No Subscriptions</Typography>
                </Grid>
              )}
            </TableContainer>
          </>
        )}
        <Divider sx={{ height: 2, backgroundColor: "blue" }} />
        <Typography variant="h5" gutterBottom>
          Asset Media
        </Typography>
        <div
          style={{ justifyContent: "center", display: "flex" }}
          className="ticket-media"
        >
          {assetData?.file_name && (
            <div className="media-container view-media">
              {assetData?.file_type === "Photo" ? (
                <img
                  className="upload-img"
                  src={`${process.env.REACT_APP_CLOUD_FRONT_URL}/${assetData?.file_name}`}
                  alt="lift-media"
                  onClick={() => handleMediaOpen(assetData?.file_name)}
                />
              ) : (
                <video
                  controls
                  width="100%"
                  height="140px"
                  onClick={() => handleMediaOpen(assetData?.file_name)}
                >
                  <source
                    src={`${process.env.REACT_APP_CLOUD_FRONT_URL}/${assetData?.file_name}`}
                    type="video/mp4" // Specify the MIME type of your video file
                  />
                </video>
              )}
            </div>
          )}
        </div>
      </Card>
    </DashboardLayout>
  );
};

export default CustomerViewAsset;
