import * as React from "react";
import { useState } from "react";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  IconButton,
  Modal,
  TextField,
  Tooltip,
} from "@mui/material";

import { Edit as EditIcon } from "@mui/icons-material";
import axios from "../../api";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SoftBox from "components/SoftBox";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EmployeeEditTicket from "./EmployeeEditTicket";
import { useSnackbar } from "components/AlertMessages/SnackbarContext";
import StatusHistory from "examples/ReuseFunctions/StatusHistory";
import { Field, Form, Formik } from "formik";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import StatusTracker from "examples/ReuseFunctions/StatusTracker";

const getMuiTheme = (theme) =>
  createTheme({
    components: {
      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            backgroundColor: theme.palette.background.default,
          },
        },
      },
    },
  });
const customScrollbarStyle = {
  "&::-webkit-scrollbar": {
    width: "8px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#44444461",
    borderRadius: "8px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#666",
  },
};
const options = {
  selectableRows: "none",
  selectableRowsHeader: false,
  elevation: 0,
};

const EmployeeTicketList = ({ onClose }) => {
  const user = useSelector((state) => state.auth.user);
  const navigate = useNavigate();
  const [rows, setRows] = React.useState([]);
  const [serviceReason, setServiceReason] = useState(null);

  const { fetchError, fetchSuccess } = useSnackbar();
  const location = useLocation();
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-US", options).format(date);
  };
  // const EmployeeRole = localStorage.getItem("service_role") || "Employees";
  const fetchData = React.useCallback(
    async (statusId) => {
      try {
        const response = await axios.get(`/tickets/employee/${user?.userId}`, {
          params: { status_id: statusId },
        });
        const { data } = response;
        setRows(data?.list);
      } catch (error) {
        console.error("Error: ", error);
      }
    },
    [user?.userId]
  );
  React.useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const status = queryParams.get("status_id");
    fetchData(status);
  }, [location, fetchData]);

  const handleStatus = async (ticket) => {
    const statusdata = StatusHistory(
      ticket?.status_history,
      user?.name,
      ticket?.status_name
    );
    const ticketData = {
      status_id: 3,
      status_history: statusdata,
    };
    const trackerData = StatusTracker(
      ticket?.status_tracker,
      "Work started",
      "In-Progress",
      3,
      user?.name,
      ticket?.employee_name,
      ticket?.employee_phone
    );
    ticketData.status_tracker = trackerData;
    try {
      await axios.put(`/tickets/${ticket?.ticket_id}`, { ticketData });
      fetchData();
      fetchSuccess("Ticket updated to in-progress");
    } catch (err) {
      fetchError("Unable to Update Ticket");
    }
  };
  const handleServiceUpdate = async (selectedTicket) => {
    const reason = serviceReason || "Service Update from Employee";
    const trackerData = StatusTracker(
      selectedTicket?.status_tracker,
      reason,
      "In Progress",
      3,
      user?.name,
      selectedTicket?.employee_name,
      selectedTicket?.employee_phone || ""
    );
    const ticketData = {
      status_tracker: trackerData,
      status_id: 3,
    };
    try {
      const response = await axios.put(`/tickets/${selectedTicket.ticket_id}`, {
        ticketData,
      });

      fetchData();
      handleServiceClose();
      setServiceReason(null);
      fetchSuccess(response?.data?.message);
    } catch (err) {
      fetchError(err.response.data.error);
    }
  };

  const columns = [
    { name: "category_name", label: "Category" },
    { name: "customer_name", label: "Customer" },
    { name: "customer_phone", label: "Phone" },
    {
      name: "priority_rank",
      label: "Priority",
      options: {
        filter: false,
        customBodyRender: (value) => {
          return (
            <span
              className="active-btn"
              style={{
                borderColor:
                  value === "High"
                    ? "#00bdaa"
                    : value === "Medium"
                    ? "#ffaa00"
                    : "#fe346e",
              }}
            >
              {value}
            </span>
          );
        },
      },
    },
    {
      name: "description",
      label: "Description",
      options: {
        filter: false,
        customBodyRender: (value) => {
          const displayValue =
            value?.length > 20 ? `${value.substring(0, 15)}...` : value;
          return (
            <Tooltip title={value}>
              <span>{displayValue}</span>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "address",
      label: "Address",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta) => {
          const { currentTableData, rowIndex } = tableMeta;
          const data = tableMeta?.tableData[currentTableData[rowIndex]?.index];
          const city = data?.city_name;
          const state = data?.state_name;
          const addressType = data?.address_type;
          const addressData = `${value}, ${city}, ${state},${addressType}`;

          const displayValue =
            addressData?.length > 20
              ? `${addressData.substring(0, 20)}...`
              : addressData;
          return (
            <Tooltip title={addressData}>
              <span>{displayValue}</span>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "created_at",
      label: "Created At",
      options: {
        filter: false,
        customBodyRender: (value) => formatDate(value),
      },
    },
    { name: "status_name", label: "Status" },
    {
      name: "id",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const { currentTableData, rowIndex } = tableMeta;
          const ticketData =
            tableMeta?.tableData[currentTableData[rowIndex]?.index];
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <IconButton
                aria-label="edit"
                onClick={() =>
                  navigate(`/employee/ticket-view/${ticketData.ticket_id}`)
                }
              >
                <VisibilityIcon />
              </IconButton>
              {[3].includes(ticketData?.status_id) && (
                <IconButton
                  aria-label="edit"
                  onClick={() => handleOpen(ticketData)}
                >
                  <EditIcon />
                </IconButton>
              )}
              {ticketData?.status_id === 2 &&
                (!ticketData?.employee_arrival_date ? (
                  <Button
                    sx={{
                      position: "relative",
                    }}
                    size="small"
                    variant="contained"
                    onClick={() => {
                      handleModalOpen(ticketData);
                    }}
                  >
                    Arrival Date
                  </Button>
                ) : (
                  <Button
                    sx={{
                      position: "relative",
                    }}
                    size="small"
                    variant="contained"
                    onClick={() => {
                      handleStatus(ticketData);
                    }}
                  >
                    Start
                  </Button>
                ))}

              <>
                {ticketData?.status_id === 3 && (
                  <Button
                    sx={{
                      position: "relative",
                    }}
                    size="small"
                    variant="contained"
                    onClick={() => handleServiceOpen(ticketData)}
                  >
                    Service Update
                  </Button>
                )}
              </>
            </Box>
          );
        },
      },
    },
  ];

  const [isEditOpen, setIsEditOpen] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isServiceOpen, setIsServiceOpen] = React.useState(false);
  const [selectedTicket, setSelectedTicket] = React.useState(null);

  const handleOpen = (ticketData) => {
    setSelectedTicket(ticketData);
    setIsEditOpen(true);
  };

  const handleClose = () => {
    setIsEditOpen(false);
  };
  const handleModalOpen = (ticketData) => {
    setSelectedTicket(ticketData);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };
  const handleServiceOpen = (ticketData) => {
    setSelectedTicket(ticketData);
    setIsServiceOpen(true);
  };

  const handleServiceClose = () => {
    setIsServiceOpen(false);
  };

  const today = new Date();
  const formattedToday = today.toISOString().split("T")[0];
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card
        style={{ padding: "24px", margin: "16px auto", maxWidth: "2000px" }}
      >
        <SoftBox
          sx={{
            "& .MuiTableRow-root:not(:last-child)": {
              "& td": {
                borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                  `${borderWidth[1]} solid ${borderColor}`,
              },
            },
          }}
        >
          <ThemeProvider theme={getMuiTheme}>
            <MUIDataTable
              className="manage-tickets"
              title={"Manage Tickets"}
              data={rows}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
        </SoftBox>
      </Card>
      {isEditOpen && (
        <Modal open={isEditOpen} onClose={handleClose}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              borderRadius: "8px",
              boxShadow: 24,
              p: 4,
              maxWidth: 500,
              maxHeight: "calc(100vh - 100px)",
              overflow: "auto",
              ...customScrollbarStyle,
            }}
          >
            <EmployeeEditTicket
              employeeTicketData={selectedTicket}
              onClose={handleClose}
              fetchData={fetchData}
            />
          </Box>
        </Modal>
      )}
      {isServiceOpen && (
        <Modal open={isServiceOpen} onClose={handleServiceClose}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              borderRadius: "8px",
              boxShadow: 24,
              p: 4,
              maxWidth: 500,
              maxHeight: "calc(100vh - 100px)",
              overflow: "auto",
              ...customScrollbarStyle,
            }}
          >
            <Grid item xs={12}>
              <SoftBox>
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                >
                  Update Service
                </SoftTypography>
                <TextField
                  // name="onhold_reason"
                  variant="outlined"
                  onChange={(event) =>
                    setServiceReason(
                      event.target.value === "" ? null : event.target.value
                    )
                  }
                  value={serviceReason}
                  // onChange={(event) => {
                  //   const data = event.target.value;
                  //   setFieldValue("onhold_reason", data ? data : null);
                  // }}
                  fullWidth
                  // error={errors.onhold_reason && touched.onhold_reason}
                  // helperText={touched.onhold_reason && errors.onhold_reason}
                />
              </SoftBox>
            </Grid>

            <Grid item xs={12} sx={{ marginTop: "20px" }}>
              <Grid container spacing={2}>
                {serviceReason && (
                  <Grid item>
                    <SoftButton
                      className="success-btn"
                      variant="gradient"
                      color="success"
                      type="submit"
                      onClick={() => handleServiceUpdate(selectedTicket)}
                    >
                      Save
                    </SoftButton>
                  </Grid>
                )}
                <Grid item>
                  <SoftButton
                    className="cancel-btn"
                    variant="gradient"
                    color="warning"
                    onClick={handleServiceClose}
                  >
                    Cancel
                  </SoftButton>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      )}
      {isModalOpen && (
        <Modal open={isModalOpen} onClose={handleModalClose}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              borderRadius: "8px",
              boxShadow: 24,
              p: 4,
              maxWidth: 500,
              maxHeight: "calc(100vh - 100px)",
              overflow: "auto",
              ...customScrollbarStyle,
            }}
          >
            <Formik
              initialValues={{
                arrival_date: "",
              }}
              onSubmit={async (values) => {
                const ticketData = {};
                const trackerData = StatusTracker(
                  selectedTicket?.status_tracker,
                  `Employee will arrive on ${values.arrival_date}`,
                  "Todo",
                  3,
                  user?.name,
                  selectedTicket?.employee_name,
                  selectedTicket?.employee_phone
                );
                ticketData.status_tracker = trackerData;
                ticketData.employee_arrival_date = values.arrival_date;
                try {
                  await axios.put(`/tickets/${selectedTicket?.ticket_id}`, {
                    ticketData,
                  });
                  fetchData();
                  handleModalClose();
                  fetchSuccess("Arrival date updated successfully");
                } catch (err) {
                  fetchError("Unable to Update Ticket");
                }
              }}
            >
              {() => (
                <Form>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                      >
                        Arraival Date
                      </SoftTypography>
                      <Field
                        className="date-field"
                        name="arrival_date"
                        as={TextField}
                        InputProps={{
                          inputProps: {
                            min: formattedToday,
                          },
                        }}
                        type="date"
                        fullWidth
                        variant="outlined"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sx={{ mt: 2 }}>
                    <Grid container spacing={2}>
                      <Grid item>
                        <SoftButton
                          className="success-btn"
                          variant="gradient"
                          color="success"
                          type="submit"
                        >
                          Save
                        </SoftButton>
                      </Grid>
                      <Grid item>
                        <SoftButton
                          className="cancel-btn"
                          variant="gradient"
                          color="warning"
                          onClick={handleModalClose}
                        >
                          Cancel
                        </SoftButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Box>
        </Modal>
      )}
    </DashboardLayout>
  );
};

export default EmployeeTicketList;
