import * as React from "react";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, Card, IconButton, Tooltip } from "@mui/material";
import { Edit as EditIcon } from "@mui/icons-material";
import axios from "../../api";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SoftBox from "components/SoftBox";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SoftButton from "components/SoftButton";
// import EditLift from "./EditLift";
import AddAsset from "./AddAsset";
import EditAsset from "./EditAsset";

const getMuiTheme = (theme) =>
  createTheme({
    components: {
      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            backgroundColor: theme.palette.background.default,
          },
        },
      },
    },
  });

const options = {
  selectableRows: "none",
  selectableRowsHeader: false,
  elevation: 0,
};

const AssetsList = () => {
  const [rows, setRows] = React.useState([]);
  const [error, setError] = React.useState(null);
  const [isEditOpen, setEditOpen] = React.useState(false);
  const [isAddOpen, setAddOpen] = React.useState(false);
  const [assetData, setAssetData] = React.useState(null);

  const handleEditOpen = (data) => {
    setAssetData(data);
    setEditOpen(true);
  };

  const handleEditClose = () => {
    setEditOpen(false);
  };
  const handleAddOpen = () => {
    setAddOpen(true);
  };

  const handleAddClose = () => {
    setAddOpen(false);
  };
  const columns = [
    { name: "asset_service_id", label: "Service Id" },
    { name: "asset_type_name", label: "Asset Type" },
    {
      name: "asset_name",
      label: "Asset Name",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta) => {
          const { currentTableData, rowIndex } = tableMeta;
          const data = tableMeta?.tableData[currentTableData[rowIndex]?.index];
          const assetName = data?.asset_name;
          const displayValue =
            assetName?.length > 20
              ? `${assetName.substring(0, 20)}...`
              : assetName;
          return (
            <Tooltip title={assetName}>
              <span>{displayValue}</span>
            </Tooltip>
          );
        },
      },
    },
    { name: "customer_name", label: "Customer" },
    {
      name: "address",
      label: "Address",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta) => {
          const { currentTableData, rowIndex } = tableMeta;
          const data = tableMeta?.tableData[currentTableData[rowIndex]?.index];
          const city = data?.city_name;
          const state = data?.state_name;
          const addressType = data?.address_type;
          const addressData = `${value}, ${city}, ${state},${addressType}`;

          const displayValue =
            addressData?.length > 20
              ? `${addressData.substring(0, 20)}...`
              : addressData;
          return (
            <Tooltip title={addressData}>
              <span>{displayValue}</span>
            </Tooltip>
          );
        },
      },
    },

    {
      name: "id",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const { currentTableData, rowIndex } = tableMeta;
          const data = tableMeta?.tableData[currentTableData[rowIndex]?.index];
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <IconButton
                aria-label="edit"
                onClick={() => handleEditOpen(data)}
              >
                <EditIcon />
              </IconButton>
              {/* <IconButton
                aria-label="edit"
                onClick={() => navigate(`/admin/lifts-view/${data?.lift_id}`)}
              >
                <VisibilityIcon />
              </IconButton> */}
            </Box>
          );
        },
      },
    },
  ];

  const fetchData = React.useMemo(
    () => async () => {
      try {
        const response = await axios.get("/assets");
        setRows(response?.data?.list);
      } catch (error) {
        setError(error);
      }
    },
    []
  );
  const [customers, setCustomers] = React.useState(null);
  const fetchCustomersData = React.useMemo(
    () => async () => {
      try {
        const customerResponse = await axios.get("/customer", {
          params: {
            is_active: 1,
          },
        });
        setCustomers(customerResponse?.data?.list);
      } catch (error) {
        setError(error);
      }
    },
    []
  );
  React.useEffect(() => {
    fetchData();
    fetchCustomersData();
  }, [fetchData, fetchCustomersData]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card
        style={{ padding: "24px", margin: "16px auto", maxWidth: "2000px" }}
      >
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <SoftButton variant="gradient" color="info" onClick={handleAddOpen}>
            Add Asset
          </SoftButton>
        </div>
        <SoftBox
          sx={{
            "& .MuiTableRow-root:not(:last-child)": {
              "& td": {
                borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                  `${borderWidth[1]} solid ${borderColor}`,
              },
            },
          }}
        >
          <ThemeProvider theme={getMuiTheme}>
            <MUIDataTable
              className="manage-lifts"
              title={"Manage Assets"}
              data={rows}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
          {error && <div>Error: {error.message}</div>}
        </SoftBox>
      </Card>
      {isEditOpen && (
        <EditAsset
          isOpen={isEditOpen}
          onClose={handleEditClose}
          assetData={assetData}
          fetchData={fetchData}
        />
      )}
      {isAddOpen && (
        <AddAsset
          isOpen={isAddOpen}
          onClose={handleAddClose}
          fetchData={fetchData}
          customers={customers}
        />
      )}
    </DashboardLayout>
  );
};

export default AssetsList;
