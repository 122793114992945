import React, { useCallback, useEffect, useState } from "react";
import axios from "../../api";
import {
  Card,
  Button,
  TextField,
  FormControl,
  Grid,
  Box,
  Autocomplete,
} from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "components/AlertMessages/SnackbarContext";

const todayDate = new Date().toISOString().split("T")[0];

const validationSchema = Yup.object({
  subscription_type_id: Yup.string().required("Subscription type is required"),
  start_date: Yup.string()
    .matches(
      /^\d{4}-\d{2}-\d{2}$/,
      "Start date must be in the format YYYY-MM-DD"
    )
    .required("Start date is required")
    .test("is-valid-date", "Start date is not a valid date", (value) => {
      return !isNaN(new Date(value).getTime());
    }),
  end_date: Yup.string()
    .matches(/^\d{4}-\d{2}-\d{2}$/, "End date must be in the format YYYY-MM-DD")
    .required("End date is required")
    .test("is-valid-date", "End date is not a valid date", (value) => {
      return !isNaN(new Date(value).getTime());
    })
    .test(
      "is-greater-than-today",
      "End date must be greater than today",
      (value) => {
        if (!value) return true; // Skips validation if the value is not set yet
        const endDate = new Date(value);
        return endDate > new Date(todayDate);
      }
    ),
});

const EditSubscription = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [subscriptionData, setSubscriptionData] = useState(null);
  const { fetchError, fetchSuccess } = useSnackbar();
  const [assetTypesData, setAssetTypesData] = useState([]);
  const [subscriptionTypes, setSubscriptionTypes] = useState([]);
  const [selectedSubscriptionType, setSelectedSubscriptionType] =
    useState(null);
  const [assets, setAssets] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedAssetType, setSelectedAssetType] = useState(null);
  const [selectedAsset, setSelectedAsset] = useState(null);

  const fetchSubscriptionData = useCallback(async () => {
    try {
      const response = await axios.get(`/subscription/${id}`);
      const { data } = response;
      setSelectedCustomer(data?.customer_id);
      setSelectedAssetType({
        asset_type_id: data?.asset_type_id,
        asset_type_name: data?.asset_type_name,
      });
      setSelectedAsset({
        asset_id: data?.asset_id,
        asset_name: data?.asset_name,
      });
      setSelectedSubscriptionType({
        subscription_type_id: data?.subscription_type_id,
        subscription_type_name: data?.subscription_type,
      });
      setSubscriptionData(data);
    } catch (error) {
      console.error("Error:", error);
    }
  }, [id]);

  useEffect(() => {
    fetchSubscriptionData();
  }, [fetchSubscriptionData]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const today = new Date().toISOString().split("T")[0];
  const fetchAssetsData = useCallback(async () => {
    try {
      const assetTypesData = await axios.get("/asset-types", {
        params: { is_active: 1 },
      });
      setAssetTypesData(assetTypesData?.data?.list || []);
    } catch (error) {
      console.error("Error fetching asset types:", error);
    }
  }, []);

  useEffect(() => {
    fetchAssetsData();
  }, [fetchAssetsData]);

  const fetchAssets = useCallback(async () => {
    if (selectedCustomer && selectedAssetType?.asset_type_id) {
      try {
        const response = await axios.get(
          `/assets/${selectedAssetType?.asset_type_id}/${selectedCustomer}`
        );
        setAssets(response?.data?.list || []);
      } catch (error) {
        console.error("Error fetching assets:", error);
      }
    }
  }, [selectedCustomer, selectedAssetType?.asset_type_id]);

  useEffect(() => {
    fetchAssets();
  }, [fetchAssets]);

  const fetchSubsciptionTypes = useCallback(async () => {
    try {
      const subscriptionTypes = await axios.get(
        `/subscription-types/assets/${selectedAssetType?.asset_type_id}`,
        {
          params: { is_active: 1 },
        }
      );
      setSubscriptionTypes(subscriptionTypes?.data?.list || []);
    } catch (error) {
      console.error("Error fetching asset types:", error);
    }
  }, [selectedAssetType?.asset_type_id]);

  useEffect(() => {
    fetchSubsciptionTypes();
  }, [fetchSubsciptionTypes]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card
        style={{
          padding: "30px",
          width: "100%",
          borderRadius: "8px",
        }}
      >
        <SoftTypography variant="h5" style={{ marginBottom: "18px" }}>
          Edit Subscription
        </SoftTypography>
        <Formik
          enableReinitialize
          initialValues={{
            subscription_type_id:
              subscriptionData?.subscription_type_id || null,
            start_date: formatDate(subscriptionData?.start_date),
            end_date: formatDate(subscriptionData?.end_date),
          }}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            delete values.asset_type_id;
            try {
              const response = await axios.put(`/subscription/${id}`, values);
              fetchSuccess(response.data.message);
              navigate(`/subscriptions-list`);
            } catch (error) {
              fetchError(error.response.data.error);
            }
          }}
        >
          {({ errors, touched, setFieldValue, values }) => (
            <Form
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      User
                    </SoftTypography>
                    <TextField
                      variant="outlined"
                      name="customer_id"
                      fullWidth
                      value={subscriptionData?.name}
                      disabled
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Asset Type
                    </SoftTypography>
                    <Autocomplete
                      options={assetTypesData || []}
                      getOptionLabel={(option) => option.asset_type_name}
                      value={selectedAssetType}
                      onChange={(event, value) => {
                        setFieldValue(
                          "asset_type_id",
                          value?.asset_type_id || ""
                        );
                        setSelectedAssetType(value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={errors.asset_type_id && touched.asset_type_id}
                          helperText={
                            touched.asset_type_id && errors.asset_type_id
                          }
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Asset
                    </SoftTypography>
                    <Autocomplete
                      options={assets || []}
                      getOptionLabel={(option) => option.asset_name}
                      value={selectedAsset}
                      onChange={(event, value) => {
                        setFieldValue("asset_id", value?.asset_id || "");
                        setSelectedAsset(value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={errors.asset_id && touched.asset_id}
                          helperText={touched.asset_id && errors.asset_id}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Subscription Type
                    </SoftTypography>
                    <Autocomplete
                      options={subscriptionTypes || []}
                      getOptionLabel={(option) =>
                        option?.subscription_type_name
                      }
                      value={selectedSubscriptionType}
                      onChange={(event, value) => {
                        setFieldValue(
                          "subscription_type_id",
                          value?.subscription_type_id || ""
                        );
                        setSelectedSubscriptionType(value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={
                            errors.subscription_type_id &&
                            touched.subscription_type_id
                          }
                          helperText={
                            touched.subscription_type_id &&
                            errors.subscription_type_id
                          }
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Start Date
                    </SoftTypography>
                    <Field
                      className="date-field"
                      name="start_date"
                      as={TextField}
                      InputProps={{
                        inputProps: {
                          min: today,
                        },
                      }}
                      type="date"
                      fullWidth
                      variant="outlined"
                      error={!!errors.start_date && touched.start_date}
                      helperText={touched.start_date && errors.start_date}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      End Date
                    </SoftTypography>
                    <Field
                      className="date-field"
                      name="end_date"
                      as={TextField}
                      InputProps={{
                        inputProps: {
                          min: values?.start_date || today,
                        },
                      }}
                      type="date"
                      fullWidth
                      variant="outlined"
                      error={!!errors.end_date && touched.end_date}
                      helperText={touched.end_date && errors.end_date}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Button
                    className="success-btn"
                    sx={{
                      position: "relative",
                      minWidth: 100,
                    }}
                    variant="contained"
                    type="submit"
                    color="success"
                  >
                    Update
                  </Button>

                  <Button
                    className="cancel-btn"
                    sx={{
                      position: "relative",
                      minWidth: 100,
                      ml: 2.5,
                    }}
                    variant="contained"
                    color="warning"
                    onClick={() => navigate(`/subscriptions-list`)}
                    type="cancel"
                  >
                    Cancel
                  </Button>
                </Box>
              </Grid>
            </Form>
          )}
        </Formik>
      </Card>
    </DashboardLayout>
  );
};

export default EditSubscription;
